/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Zachary's Portfolio",
  description:
    "My passion for data and actuarial science, is driven by a curiosity for emerging technologies and continuous learning.",
  og: {
    title: "Zachary Bushby Portfolio",
    type: "website",
    url: "http://zacharybushby.com/",
  },
};

//Home Page
const greeting = {
  title: "Zachary Bushby",
  logo_name: "ZacharyBushby",
  nickname: "Hi I'm Zach!",
  subTitle:
    " I have a passion for actuarial and data science, driven by a curiosity for emerging technologies and continuous learning.",
  resumeLink:
    "https://docs.google.com/document/d/19uQsQsoCwPdZaBDxLT2-k0keSVU-0Pdc/edit?usp=sharing&ouid=105209283351272894952&rtpof=true&sd=true",
  portfolio_repository: "",
  githubProfile: "https://github.com/zbushby",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/zachary-bushby/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin 
  },
  {
    name: "Gmail",
    link: "mailto:zachbush678@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail #D14836
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/zach.bushby/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook #1877F2
  },
  {
    name: "Github",
    link: "https://www.github.com/zbushby/",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Actuarial & Financial Risk",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Leveraged SQL and R to execute comprehensive data validation and analysis",
        "⚡ Leveraged Excel for in-depth data analysis and visualisation, aiding in valuations and market research",
      ],
      softwareSkills: [
        {
          skillName: "R",
          fontAwesomeClassname: "logos:r-lang",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "SQL",
          fontAwesomeClassname: "vscode-icons:file-type-sql",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Excel",
          fontAwesomeClassname: "vscode-icons:file-type-excel",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Data Science & AI",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developed predictive models with machine learning methods such GBM, XGBoost, and GLMs",
        "⚡ Quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Pytorch",
          fontAwesomeClassname: "simple-icons:pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
        {
          skillName: "Dplyr",
          imageSrc: "dplyr.png",
        },
        {
          skillName: "Tibble",
          imageSrc: "Tibble.png",
        },
        {
          skillName: "RegEx",
          imageSrc: "stringr.png",
        },
        {
          skillName: "Shiny",
          imageSrc: "Shiny.png",
        },
        {
          skillName: "Tidyr",
          imageSrc: "tidyr.png",
        },
        {
          skillName: "ggplot2",
          imageSrc: "ggplot2.png",
        },
        {
          skillName: "Tidyverse",
          imageSrc: "tidyverse.png",
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
  ],
};

const degrees = {
  degrees: [
  ],
};

const certifications = {
  certifications: [
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Education",
  description:
    "My passion for learning and growth was the foundation of my internships at Finity, Deloitte, and KPMG, where I sharpened my ability to turn complex data into strategic insights. As a Shift Supervisor and President of MASS, I utilise my interpersonal skills to prioritise exceptional client service and build strong professional relationships.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Internships",
      experiences: [
        {
          title: "Actuarial & Financial Risk Vacationer",
          company: "KPMG",
          company_url: "https://kpmg.com/au/en/home/services/advisory/risk-consulting/financial-risk-management.html",
          logo_path: "KPMG.svg",
          duration: "Nov 2023 - Feb 2024",
          location: "Melbourne, Australia",
          description:
            "Financial Risk Management (Credit & Market Risk)",
          color: "#000000",
        },
        {
          title: "Actuarial Intern",
          company: "Finity Consulting",
          company_url: "https://finity.com.au/",
          logo_path: "Finity.png",
          duration: "Aug 2023 - Oct 2023",
          location: "Melbourne, Australia",
          description:
            "Private Health and General Insurance Actuarial Intern",
          color: "#ee3c26",
        },
        {
          title: "Data Analyst Intern",
          company: "Deloitte",
          company_url:
            "https://www2.deloitte.com/be/en/pages/risk/solutions/regulatory-compliance.html",
          logo_path: "deloitte.png",
          duration: "Jun 2023 - Jul 2023",
          location: "Melbourne, Australia",
          description:
            "Risk Advisory (Regulatory & Compliance)",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Shift Supervisor / Cook / Drinks & Bar",
          company: "The Pancake Parlour",
          company_url: "https://www.pancakeparlour.com.au/",
          logo_path: "Pancake_Parlour .jpg",
          duration: "Feb 2021 - Present",
          location: "Malvern East, Australia",
          description:
            "As a Shift Supervisor my responsibilities include overseeing the restaurant in a management sense, delegating duties, managing of financial figures (wage plan), and problem-solving. As a casual employee, I constantly rotate between responsibilities/areas of the restaurant including Supervising, Cooking and Bar & Drinks Production.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "University Club Involvement",
      experiences: [
        {
          title: "President",
          company: "Monash Actuarial Student Society",
          company_url: "",
          logo_path: "MASS.png",
          duration: "Sep 2023 - Present",
          location: "Clayton, Australia",
          description:
            "",
          color: "#4285F4",
        },
        {
          title: "Treasurer",
          company: "Monash Judo Club",
          company_url: "",
          logo_path: "judoclub.jpeg",
          duration: "Feb 2023 - Feb 2024",
          location: "Clayton, Australia",
          description:
            "",
          color: "#D83B01",
        },
        {
          title: "Subcommittee",
          company: "Monash Actuarial Student Society",
          company_url: "",
          logo_path: "MASS.png",
          duration: "Jan 2023 - Sep 2023",
          location: "Clayton, Australia",
          description:
            "As a subcommittee member in the Monash Actuarial Student Society, I collaborated closely and supported directors from planning to executing engaging events, contributing to the growth of the society’s presence within the actuarial community.",
          color: "#000000",
        },
      ],
    },
    {
      title: "Education",
      experiences: [
        {
          title: "Bachelor of Actuarial Science and Bachelor of Commerce",
          company: "Monash University",
          company_url: "",
          logo_path: "monash.png",
          duration: "Feb 2021 - Nov 2024",
          location: "Clayton, Australia",
          description:
            "",
          color: "#000000",
        },
        {
          title: "High School",
          company: "John Monash Science School",
          company_url: "",
          logo_path: "johnmonash.jpeg",
          duration: "Jan 2018 - Dec 2020",
          location: "Clayton, Australia",
          description:
            "Doherty House Captain 2020, JMSS Gold Diploma",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Achievements",
      experiences: [
        {
          title: "Top Talks 2021",
          company: "",
          company_url: "",
          logo_path: "VCAA.svg",
          duration: "",
          location: "",
          description:
            "VCE Season of Excellence, Top Talks Presenter; Only 4 speakers were selected from Victorian Extended Investigation Cohort, presenting my research project on a hypothetical spring-tuned mass damper supported by mathematical simulations and models",
          color: "#000000",
        },
        {
          title: "International Young Physicist Tournament",
          company: "",
          company_url: "",
          logo_path: "IYPT.png",
          duration: "",
          location: "",
          description:
            "A part of the national team to compete in the Internation Young Physicist Tournament in Romania. Unfortunately, our research could not be presented as the pandemic restricted all international travel.",
          color: "#ee3c26",
        },
        {
          title: "John Monash Gold Diploma",
          company: "",
          company_url: "",
          logo_path: "johnmonash.jpeg",
          duration: "",
          location: "",
          description:
            "The JMSS Gold Diploma is awarded to students who have made an outstanding contribution to the school during their high school career.",
          color: "#ee3c26",
        },
        {
          title: "CERN Beamlines shortlist Top 23",
          company: "",
          company_url: "",
          logo_path: "CERN.png",
          duration: "",
          location: "",
          description:
            "International physics competition for high school students organised by CERN, the European Organization for Nuclear Research, in Geneva, Switzerland and DESY, Hamburg, Germany. 198 research proposals from 47 countries.",
          color: "#ee3c26",
        },
        {
          title: "Australian Young Physicist Tournament",
          company: "",
          company_url: "",
          logo_path: "IYPT.png",
          duration: "",
          location: "",
          description:
            "Competed in the national young physicist tournament, our team of 3 competed in Brisbane against schools from various states. We achieved high distinction and came first in the tournament.",
          color: "#ee3c26",
        },
        {
          title: "Thailand International Science Fair",
          company: "",
          company_url: "",
          logo_path: "johnmonash.jpeg",
          duration: "",
          location: "",
          description:
            "Selected to present our research project with 5 of my peers in the Thailand International Science Fair.",
          color: "#ee3c26",
        },
      ],
    },
  ],
};

// Projects Page
//My portfolio features a range of projects that merge financial services with the latest in data science techniques. Here, you'll find projects that spotlight the use of advanced statistical modelling and natural language processing to solve tough challenges in actuarial and financial risk.
const projectsHeader = {
  title: "Projects",
  description:
    "",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
  ],
};
// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "photo_zach.png",
    description:
      " Send me a message! I would love to get in touch! Email: zachbush678@gmail.com",
  },
  blogSection: {
    title: "",
    subtitle:
      "",
    link: "",
    avatar_image_path: "",
  },
  addressSection: {
    title: "",
    subtitle:
      "",
    locality: "",
    country: "",
    region: "",
    postalCode: "",
    streetAddress: "",
    avatar_image_path: "",
    location_map_link: "",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
